<template>
  <v-tooltip top :color="color">
    <!--  -->
    <template v-slot:activator="{ on, attrs }">
      <v-avatar :class="classes" size="18" v-bind="attrs" v-on="on">
        <v-icon :color="color"> {{ icon }} </v-icon>
      </v-avatar>
    </template>
    <span v-html="message"></span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-alert-circle',
      required: false,
    },
    message: {
      type: String,
      default: '',
      required: true,
    },
    color: {
      type: String,
      default: 'red darken-1',
      required: false,
    },
    classes: {
      type: String,
      default: 'mx-0',
      required: false,
    },
  },
};
</script>
